import React, { Component } from 'react'
import NavbarSecundaria from '../components/NavbarSecundaria'
import NavbarPortefolios from '../components/NavbarPortefolios'


export default class Portefolio extends Component {



    render() {
        return (
            <div className="portefolio">
                <NavbarSecundaria />
                <NavbarPortefolios />

            </div>
        )
    }
}
