import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export default class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <LinkScroll
          className="nav-home"
          activeClass="active"
          to="home-section-home"
          spy={true}
          smooth={true}
          offset={0}
          duration={800}
        >
          Home{" "}
        </LinkScroll>

        <LinkScroll
          className="nav-quemsou"
          activeClass="active"
          to="home-section-quemsou"
          spy={true}
          smooth={true}
          offset={0}
          duration={800}
        >
          Quem sou{" "}
        </LinkScroll>

        <LinkScroll
          className="nav-maquilhagem"
          activeClass="active"
          to="home-section-serviços"
          spy={true}
          smooth={true}
          offset={0}
          duration={800}
        >
          Serviços{" "}
        </LinkScroll>
        <LinkScroll
          className="nav-serviços"
          activeClass="active"
          to="home-section-laser"
          spy={true}
          smooth={true}
          offset={0}
          duration={800}
        >
          Laser{" "}
        </LinkScroll>

        <LinkScroll
          className="nav-contactos"
          activeClass="active"
          to="home-section-contactos"
          spy={true}
          smooth={true}
          offset={0}
          duration={800}
        >
          Contactos{" "}
        </LinkScroll>
        <Link to="/portefolio" className="nav-portefolio">
          Portefólio
        </Link>
      </div>
    );
  }
}
