import React from "react";
import { Link } from "react-router-dom";

export default function ButtonServices({ texto, url, children }) {
  return (
    <div>
      <Link to={url}>
        <span>{texto}</span>
      </Link>
      {children}
    </div>
  );
}
