import React, { Component } from 'react'
import NavbarSecundaria from '../components/NavbarSecundaria'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

export default class Erro404 extends Component {
    render() {
        return (
            <div className="erro404">
                <NavbarSecundaria />
                <div className="formatar-404">
                    <div className="container-erro">
                        <h1>Erro 404 - lamentamos, mas a página não foi encontrada ! </h1>
                        <Link to="/">Retornar ao inicio</Link>

                    </div></div>

                <Footer />
            </div>
        )
    }
}
