import React, { Component } from 'react'

export default class Comentarios extends Component {
    render() {
        return (
            <div className="comentarios">


                <div className="post"><b><p>Recomendo vivamente</p></b><p>Exatamente como eu queria.
                A Zita tornou o meu dia ainda mais especial. Sempre atenta
                aos detalhes, muito empenhada para as que as noivas fiquem
                umas verdadeiras princesas.Resultado final: excelente! Muito Obrigada!
                     Boa sorte para o futuro. </p> <br /> </div>

                <div className="post"><b><p>Casamento Feliz</p></b><p>No meu dia a dia, não uso qualquer tipo
                de maquilhagem, apenas crede de dia  e noite. Mas adoro, por isso, no
                dia do meu casamento, tinha que o fazer. A ZIta Cleto, foi uma ótima escolha,
                    super profissional e muito simpática. Adorei o serviço e recomendo.</p> <br /> </div>

                <div className="post" ><b><p>Fantástica</p></b><p>A ZIta foi incrivel no serviço que prestou!
                A maquilhagem ficou incrível e durou o casamento inteiro, mesmo com
                lágrimas pelo meio! Fez a maquilhaem a mim, noiva, à minha mãe, tia e avó
                e garantidamente não havia ninguém tão bem maquilhado como nós! Obrigado Zita!
                    Recomendo vivamente! </p> <br /> </div>

                <div className="post"><b><p>Adorei</p></b><p>Profissional 5*, muito responsável, atenciosa e acima de
                tudo muito profissional no seu trabalho,
                recomendo sem dúvida alguma, se voltasse a casar seria novamente a minha escolha,
                só me resta agradecer pelo seu trabalho e por ter feito que o meu dia de casamento fosse
                ainda mais perfeito.
                    </p> <br /> </div>



            </div>
        )
    }
}
