import React, { Component, Suspense, lazy } from "react";
import NavbarSecundaria from "../components/NavbarSecundaria";

const BackToTop = lazy(() => import("../components/BackToTop"));
const InstaPortefolio = lazy(() => import("../components/InstaPortefolio"));

export default class PortefolioLifting extends Component {
  render() {
    return (
      <div className="portefoliomaquilhagem">
        <NavbarSecundaria />
        <h1>Lifting de Pestanas </h1>

        <Suspense fallback={<p>A carregar...</p>}>
          {" "}
          <InstaPortefolio filtro={"Lifting de Pestanas"} after={""} />
        </Suspense>

        <Suspense fallback={<p></p>}>
          {" "}
          <BackToTop ancora="#navbar-secundaria" />
        </Suspense>
      </div>
    );
  }
}
