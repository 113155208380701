/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import LogoZita from "../img/LogoZita2.png";
import quemSouImg from "../img/quemsou25.png";
import Navbar from "../components/Navbar.js";
import Serviço from "../components/Serviço.js";
import MicrobladingLogo from "../img/microbladingLogo.png";
import LimpezaPeleLogo from "../img/limpezaPele.png";
import LiftingPestanasLogo from "../img/lifting.png";
import MaquilhagemLogo from "../img/maquilhagem.png";
import MaquilhagemNoivaLogo from "../img/noivas.png";
import WorkshopsLogo from "../img/workshop.png";
import Footer from "../components/Footer";
import { FaAlignRight } from "react-icons/fa";
import { Link as LinkScroll } from "react-scroll";
// import ButtonServices from "../components/ButtonServices";
import SlideShow from "../components/SlideShow";
import VideoLoader from "../components/VideoLoader";
// import PopUp from "../components/PopUp";
import Comentarios from "../components/Comentarios";
import MicroPigmentaçaoLabiosLogo from "../img/micropigmentacaoLabios.png";
import HidrataçaoLabiosLogo from "../img/hidratacaoLabios.png";
import RemoçãoLaser from "../img/remoçaoLaser.png";
import PeelingHollywood from "../img/peelingHollywood.png";
import RemoçaoTatuagens from "../img/remoçaoTatuagens.png";
import { Link } from "react-router-dom";

export default class Home extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    // const mystyle = { display: "none" };
    return (
      <div id="home">
        <div className="home-section-home">
          <VideoLoader />
          <img src={LogoZita} className="logoHome" alt="ZitaCleto"></img>
          {/* <PopUp /> */}

          <div className="nav-mobile">
            <button type="button" className="nav-btn">
              <FaAlignRight className="nav-icon" onClick={this.handleToggle} />
            </button>
            <h6>Menu</h6>
          </div>

          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            <li>
              <LinkScroll
                onClick={this.handleToggle}
                className="nav-home"
                activeClass="active"
                to="home-section-home"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Home{" "}
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                onClick={this.handleToggle}
                className="nav-quemsou"
                activeClass="active"
                to="home-section-quemsou"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Quem sou{" "}
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                onClick={this.handleToggle}
                className="nav-serviços"
                activeClass="active"
                to="home-section-serviços"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Serviços{" "}
              </LinkScroll>
            </li>
            <li>
              <LinkScroll
                onClick={this.handleToggle}
                className="nav-serviços"
                activeClass="active"
                to="home-section-laser"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Laser{" "}
              </LinkScroll>
            </li>

            <li>
              <LinkScroll
                onClick={this.handleToggle}
                className="nav-contactos"
                activeClass="active"
                to="home-section-contactos"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Contactos
              </LinkScroll>
            </li>
            <li className="link-portefolio">
              <Link to="/portefolio">Portefólio</Link>
            </li>
            <img src={LogoZita} className="logoNavbar" alt="ZitaCleto"></img>
            <button type="button" className="nav-btn-show">
              <FaAlignRight className="nav-icon" onClick={this.handleToggle} />
            </button>
            <h6>Menu</h6>
          </ul>
          <div className="nav">
            <Navbar />
          </div>
        </div>
        <div className="home-section-quemsou">
          <div className="quemsou-content-img">
            <img src={quemSouImg} alt="Quem Sou"></img>
          </div>

          <div className="quemsou-content">
            <h1>Quem Sou? </h1>
            <p>
              Olá, eu sou a Zita Cleto e sou Maquilhadora. Falar sobre mim é um
              bocadinho difícil. Não é fácil quando se é introvertido. Posso
              afirmar, sem dúvida alguma, que trabalho com o coração pois só
              assim enfrento a dificuldade de lidar com as pessoas, visto ser um
              pouco tímida. Dedico-me a este amor há 4 anos e não pára de
              crescer. Ser maquilhadora levou-me a outros mundos, como o mundo
              das noivas, ser aquela pessoa que sem estar ao lado das noivas no
              altar sabe que o seu trabalho está lá e fazer com que se sintam
              mais bonitas e felizes nesse dia é algo que não passa despercebido
              a ninguém. Adoro o meu trabalho e assim invisto em mim para trazer
              o melhor para todas vós. E então neste meu cantinho deixo-vos com
              uma frase da Madre Teresa de Calcutá – “ Nem todos nós podemos
              fazer grandes coisas. Mas podemos fazer pequenas coisas com muito
              amor.” E assim espero fazer muitas pequenas coisas por vós tanto
              como profissional como ser humano que levem a grandes
              transformações.
            </p>
            <a className="btn-falaComigo" href="http://m.me/zitacletomakeup">
              <span>Fala comigo!</span>
            </a>
          </div>
        </div>
        {/* ---------------------INICIO SERVIÇOS */}
        <div className="home-section-serviços">
          <h1>Serviços</h1>
          <div className="serviços-container">
            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: MaquilhagemNoivaLogo,
                  imgUrl2: MaquilhagemNoivaLogo,
                  imgUrl3: MaquilhagemNoivaLogo,
                  imgUrl4: MaquilhagemNoivaLogo,
                  title: "Maquilhagem de Noiva",
                  text: "O Serviço inclui Prova de Maquilhagem, onde serão conhecidos os gostos da noiva , o seu estilo e personalidade e as suas expectativas. Serão testadas maquilhagens de acordo com as escolhas da cliente e com o meu aconselhamento de forma a ser escolhida a maquilhagem perfeita para o seu grande dia.",
                },
              }}
            >
              <Serviço
                path={MaquilhagemNoivaLogo}
                title="Maquilhagem de Noiva"
              />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: MaquilhagemLogo,
                  imgUrl2: MaquilhagemLogo,
                  imgUrl3: MaquilhagemLogo,
                  imgUrl4: MaquilhagemLogo,
                  title: "Maquilhagem",
                  text: "Maquilhagem para todo o tipo de eventos ou apenas para se mimar para um dia especial! Será realçada a sua beleza natural de acordo com o seu gosto e sempre com o melhor aconselhamento profissional.",
                },
              }}
            >
              <Serviço path={MaquilhagemLogo} title="Maquilhagem" />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: MicroPigmentaçaoLabiosLogo,
                  imgUrl2: MicroPigmentaçaoLabiosLogo,
                  imgUrl3: MicroPigmentaçaoLabiosLogo,
                  imgUrl4: MicroPigmentaçaoLabiosLogo,
                  title: "MICROPIGMENTAÇÃO DE LÁBIOS",
                  text: "É a implantação de pigmento nos lábios com a finalidade de realçar o contorno da boca, corrigir pequenas assimetrias , revitalizar e rejuvenescer os tecidos deixando os lábios com um aspeto mais saudável. Existem três tipos de micropigmentação de lábios: - Revitalização- lábios mais corados - Efeito Batom - Neutralização - clareamento de lábios Acorde já com os lábios perfeitos. Durabilidade depende da intensidade da cor aplicada e pode ser no mínimo de 1 ano.",
                },
              }}
            >
              <Serviço
                path={MicroPigmentaçaoLabiosLogo}
                title="MICROPIGMENTAÇÃO DE LÁBIOS"
              />
            </Link>
            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: HidrataçaoLabiosLogo,
                  imgUrl2: HidrataçaoLabiosLogo,
                  imgUrl3: HidrataçaoLabiosLogo,
                  imgUrl4: HidrataçaoLabiosLogo,
                  title: "HIDRATAÇÃO PROFUNDA DE LÁBIOS",
                  text: "Tratamento labial de luxo, de hidratação profunda e regeneração, que proporciona volume às fissuras labiais, rugas finas de expressão e ao chamado código de barras, devolvendo a cor rosada, visto haver uma estimulação de colagenio e de elastina. É indolor. Protocolo composto por tres sessões com intervalos de aproximadamente 30 dias. Resultados visíveis logo na primeira sessão. Durabilidade de aproximadamente 3 meses.",
                },
              }}
            >
              <Serviço
                path={HidrataçaoLabiosLogo}
                title="HIDRATAÇÃO PROFUNDA DE LÁBIOS"
              />
            </Link>
          </div>
          <div className="serviços-container">
            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: MicrobladingLogo,
                  imgUrl2: MicrobladingLogo,
                  imgUrl3: MicrobladingLogo,
                  imgUrl4: MicrobladingLogo,
                  title: "Microblading",
                  text: "Permite definir e criar a ilusão de mais pelos nas sobrancelhas. É uma técnica fio a fio, que preenche, remodela ou reconstrói por completo as sobrancelhas apresentando um resultado o mais natural possível. São usadas microlâminas com pigmento para fazer pequenos arranhões que vão imitar a existência de pelo. A duração depende dos cuidados da pessoa, do tipo de pele, entre outros factores, e poderá ser de aproximadamente 1 ano.",
                },
              }}
            >
              <Serviço path={MicrobladingLogo} title="Microblading" />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: LiftingPestanasLogo,
                  imgUrl2: LiftingPestanasLogo,
                  imgUrl3: LiftingPestanasLogo,
                  imgUrl4: LiftingPestanasLogo,
                  title: "Lifting de Pestanas",
                  text: "É utilizada uma formula em gel nas pestanas naturais levantando-as desde a raiz tornando-as maiores, mais volumosas, mais curvadas e alongadas sem uso de máscara. Os resultados são imediatos, sendo que com a consecutiva repetição do tratamento, as pestanas vão ficando cada vez mais volumosas e curvadas. A duração é de aproximadamente 6 semanas , é indolor, e permite fazer pintura e levantamento em simultâneo.",
                },
              }}
            >
              <Serviço path={LiftingPestanasLogo} title="Lifting de Pestanas" />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: LimpezaPeleLogo,
                  imgUrl2: LimpezaPeleLogo,
                  imgUrl3: LimpezaPeleLogo,
                  imgUrl4: LimpezaPeleLogo,
                  title: "Limpeza de Pele Profunda Premium",
                  text: "Reduz Oleosidade, melhora Hidratação, auxilia na renovação celular/oxigenação e clareamento e remove células mortas . -Avaliação Facial -Higienização -Peeling Ultrasónico ( auxilia na remoção das impurezas e células mortas, desobstrução de poros, acelera o processo de renovação celular, melhora viço e hidratação) -Extração  -Alta Frequência (melhora a cicatrização, estimula circulação e oxigenação) -Mascara escolhida conforme necessidade da pele -Massajador Facial ( previne envelhecimento, estimula criação de colagénio) -Serum de Vitamina C  -Ice Globes ( dao firmeza a pele e faz desinchar, aclara a pele reduzindo manchas de acne, efeito lifting de contorno, promove drenagem linfática e libertação de toxinas, ajuda penetração de seruns e cremes) -protetor solar",
                },
              }}
            >
              <Serviço
                path={LimpezaPeleLogo}
                title="Limpeza de Pele Profunda Premium"
              />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: WorkshopsLogo,
                  imgUrl2: WorkshopsLogo,
                  imgUrl3: WorkshopsLogo,
                  imgUrl4: WorkshopsLogo,
                  title: "Workshops",
                  text: "Workshop individual teórico e prático, onde é demonstrada uma maquilhagem explicando passo a passo a melhor forma de se maquilhar de acordo com a morfologia de rosto, dos olhos e o gosto pessoal, que será depois reproduzida pela formanda. Ideal para quem quer saber truques e dicas para uma maquilhagem para o dia a dia. Os workshops de maquilhagem podem ser feitos por niveis.",
                },
              }}
            >
              <Serviço path={WorkshopsLogo} title="Workshops" />
            </Link>
          </div>
        </div>
        {/* ---------------------FINAL SERVIÇOS */}

        {/* SERVIÇO Laser INICIO*/}

        <div className="home-section-laser">
          <h1>Serviços Laser</h1>
          <div>
            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: RemoçãoLaser,
                  imgUrl2: RemoçãoLaser,
                  imgUrl3: RemoçãoLaser,
                  imgUrl4: RemoçãoLaser,
                  title: "REMOÇÃO A LASER DE MICROPIGMENTAÇÂO",
                  text: "O Laser não danifica nem faz cair o pelo da sobrancelha. Ao ser aplicado, quebra as partículas do pigmento indesejado, de forma a que o organismo o expulse ou absorva. O número de sessões e a velocidade de remoção vai sempre depender de cada organismo assim como a cor, a profundidade e a qualidade da tinta utilizada na micropigmentacao.",
                },
              }}
            >
              <Serviço
                path={RemoçãoLaser}
                title="REMOÇÃO A LASER DE MICROPIGMENTAÇÂO"
              />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: PeelingHollywood,
                  imgUrl2: PeelingHollywood,
                  imgUrl3: PeelingHollywood,
                  imgUrl4: PeelingHollywood,
                  title: "PEELING DE HOLLYWOOD",
                  text: "É um procedimento de luxo, não invasivo e totalmente indolor, que trata rápida e eficazmente uma variedade de imperfeições da pele, com efeitos imediatos. Este tratamento estimula a produção de colagénio, conferindo uma maior firmeza à pele, reduzindo linhas finas e rugas, deixando a pele mais brilhante e viçosa. É adequado para TODOS TIPOS DE PELE, beneficia particularmente quem tem pele oleosa, pontos negros, poros dilatados, pele acneica, com textura irregular ou sem brilho. Diminui manchas e acne. Para melhores resultados são recomendados tratamentos regulares mensalmente.",
                },
              }}
            >
              <Serviço path={PeelingHollywood} title="PEELING DE HOLLYWOOD" />
            </Link>

            <Link
              to={{
                pathname: "/serviços",
                state: {
                  imgUrl1: RemoçaoTatuagens,
                  imgUrl2: RemoçaoTatuagens,
                  imgUrl3: RemoçaoTatuagens,
                  imgUrl4: RemoçaoTatuagens,
                  title: "REMOÇÃO OU CLAREAMENTO DE TATUAGENS",
                  text: "O laser é das tecnologias mais revolucionárias na remoção de pigmentos. Para que as tatuagens definitivas sejam removidas, o laser promove a fratura do pigmento que está na pele, sem qualquer dano na mesma ou no pelo. O número de sessões e a velocidade de remoção depende de cada organismo, da qualidade, da profundidade atingida e da quantidade de tinta utilizada.",
                },
              }}
            >
              <Serviço
                path={RemoçaoTatuagens}
                title="REMOÇÃO OU CLAREAMENTO DE TATUAGENS"
              />
            </Link>
          </div>
        </div>

        {/* NOVOOOOOOOOOO codigo Laser FIM*/}

        <div className="home-section-contactos">
          <div className="content-contactos">
            <div className="info-contactos">
              <h1>Zita Cleto</h1>
              <p className="titulo-info">E-mail:</p>
              <p className="info">zitacleto.makeup@gmail.com</p>

              <p className="titulo-info">Instagram:</p>
              <p className="info">@zitacleto.makeup</p>

              <p className="titulo-info">Facebook:</p>
              <p className="info">facebook.bom/zitacletomakeup</p>

              <p className="titulo-info">Localização:</p>
              <p className="info">5000 - Vila Real</p>
            </div>
            <div className="info-comentarios">
              <h3>Comentários</h3>
              <Comentarios />
            </div>
            <div className="insta-content">
              <SlideShow />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
