import React, { Suspense, lazy } from 'react'
import NavbarSecundaria from '../components/NavbarSecundaria';


const BackToTop = lazy(() => import('../components/BackToTop'));
const InstaPortefolio = lazy(() => import('../components/InstaPortefolio'));

const PortefolioRemoTatuagens = () => {
    return (
        <div className='portefoliomaquilhagem'>

            <NavbarSecundaria />
            <h1>Remoção de Tatuagens </h1>




            <Suspense fallback={<p>A carregar...</p>}>  <InstaPortefolio filtro={"#tattolaserremoval"} after={""} /></Suspense>

            <Suspense fallback={<p></p>}>  <BackToTop ancora='#navbar-secundaria' /></Suspense>



        </div>
    )
}

export default PortefolioRemoTatuagens;