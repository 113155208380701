import React from "react";
import Footer from "../components/Footer";
import ButtonServices from "../components/ButtonServices";
import logo from "../img/LogoZita.png";

function ServiçosPage({ location }) {
  const info = location.state;
  const { imgUrl1, imgUrl2, imgUrl3, imgUrl4, title, text } = info;

  return (
    <div className="main-container">
      <div className="img-container">
        <div
          className="img-header"
          style={{ backgroundImage: `url(${imgUrl1})` }}
        ></div>
        <div
          className="img-header"
          style={{ backgroundImage: `url(${imgUrl2})` }}
        ></div>
        <div
          className="img-header"
          style={{ backgroundImage: `url(${logo})` }}
        ></div>
        <div
          className="img-header"
          style={{ backgroundImage: `url(${imgUrl3})` }}
        ></div>
        <div
          className="img-header"
          style={{ backgroundImage: `url(${imgUrl4})` }}
        ></div>
      </div>
      <div className="info-container">
        <h1>{title}</h1>
        <p>{text}</p>
        <div className="btns-container">
          <ButtonServices url="/portefolio" texto="Portefólio" />
          <a
            href="https://calendly.com/zitacletomakeup/servicos"
          >
            <span>Agenda</span>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServiçosPage;
