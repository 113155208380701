import React from "react";

export default function Serviço({
  path,
  title,
  children,
  texto,
  url_Portefolio,
  url_Agenda,
}) {
  return (
    <div className="serviço" style={{ backgroundImage: `url(${path})` }}>
      <div className="texto-serviço">
        <h1>{title}</h1>
      </div>

      {children}
    </div>
  );
}
