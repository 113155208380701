import React, { Component } from 'react'
import { Link } from 'react-router-dom'


export default class NavbarPortefolios extends Component {


    render() {

        return (
            <div className="btns-portefolio">


                <Link to="/portefoliomicroblading">Microblading</Link>
                <Link to="/portefoliolifting">Lifting de Pestanas</Link>
                <Link to="/portefoliomaquilhagem">Maquilhagem</Link>
                <Link to="/portefoliomaquilhagemnoiva">Maquilhagem de Noiva</Link>
                <Link to="/workshops">Workshops de Maquilhagem</Link>
                <Link to="/portefolioremoçaotatuagens">Remoção de Tatuagem</Link>
                <Link to="/portefoliopeelinghollywood">Peeling de Hollywood</Link>
                <Link to="/portefolioremoçaolasermicro">Remoção Laser de Micropigmentação</Link>
                <Link to="/portefoliomicropilabios">Micropigmentação de Lábios</Link>
                <Link to="/portefoliohidrataçaolabios">Hidratação Profunda de Lábios</Link>



            </div>
        )
    }
}
