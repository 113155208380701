import React, { Component, Suspense, lazy } from "react";
import NavbarSecundaria from "../components/NavbarSecundaria";

const BackToTop = lazy(() => import("../components/BackToTop"));

const InstaPortefolio = lazy(() => import("../components/InstaPortefolio"));
export default class PortefolioMicroblading extends Component {
  render() {
    return (
      <div className="portefoliomaquilhagem">
        <NavbarSecundaria />

        <h1>Microblading </h1>

        <Suspense fallback={<p>A carregar...</p>}>
          {" "}
          <InstaPortefolio filtro={"#microblading"} after={""} />
        </Suspense>
        <Suspense fallback={<p></p>}>
          {" "}
          <InstaPortefolio
            filtro={"#microblading"}
            after={
              "&after=QVFIUmRSZAXdFYllnLU1MVW1XdGVhUUhYMHJVYTVhQ0hraTVvWm1ELVg2endWeDhVZAEZAGT0FObWtYYzJWeEhXVkNYM3JleklueVAtT3dodEdqZAzY3VW9FbXd3"
            }
          />
        </Suspense>
        <Suspense fallback={<p></p>}>
          {" "}
          <InstaPortefolio
            filtro={"#microblading"}
            after={
              "&after=QVFIUm4yRWYydlk1N18ydVpoQmdSWEtsUTM2R1d3X2ZAFVjFwcDRHbjBoTHA4U0E2bDNra1ZArVm9YN2EtaTZA1V1JMQTNIM0xHNDdvV2Fad05iZAjdVQ0RXdnpR"
            }
          />
        </Suspense>

        <Suspense fallback={<p></p>}>
          {" "}
          <BackToTop ancora="#navbar-secundaria" />
        </Suspense>
      </div>
    );
  }
}
