import React, { Component } from "react";
import video from "../img/video.mp4";

export default class VideoLoader extends Component {
  render() {
    var largura = window.screen.width;

    return (
      <div>
        {largura > 440 ? (
          <div className="displayvideo">
            <video autoPlay muted loop className="videoBackground">
              <source src={video} type="video/mp4"></source>
            </video>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    );
  }
}
