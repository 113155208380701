import React, { Component } from 'react'
import NavbarSecundaria from '../components/NavbarSecundaria'
import Footer from '../components/Footer'

export default class PoliticaPrivacidade extends Component {
    render() {
        return (
            <div className="politica-privacidade">
                <NavbarSecundaria />
                <h1>Politica de Privacidade</h1>
                <div className="politica-privacidade-texto">
                    <h3>
                        Quem sou
                    </h3>
                    <p>

                        ZitaCletoMakeup & Beauty<br />

                        O endereço do nosso site é www.zitacletomakeup.com<br />

                        Pode contactar-nos através do contacto:<br />

                        - Email:zitacleto.makeup@gmail.com<br />

                        Estamos empenhados em proteger os seus dados pessoais. Nesse sentido,
                        elaborámos a presente Política, que tem subjacente o nosso compromisso
                         de respeitar as regras de proteção de dados pessoais. Adicionalmente,
                          adotámos as medidas adequadas para assegurar a integridade e
                          confidencialidade dos seus dados, bem como todos os demais direitos
                          que lhe assistem.<br /><br />
                    </p>
                    <h3>
                        Que dados pessoais são recolhidos e como são recolhidos
                    </h3>
                    <p>
                        <b>Emails</b><br />
                        Quando entra em contacto connosco através de email, recolhemos o seu
                         endereço de correio eletrónico para que possamos responder diretamente
                          à sua comunicação.<br /><br />
                        <b>Contacto telefónico</b><br />

                        Quando entra em contacto connosco através de chamada telefónica, podemos
                        recolher o seu número de telefone, caso seja necessário entrarmos em novo
                         contacto consigo, no âmbito das informações ou dos serviços a prestar.<br /><br />

                        <b>Conteúdo incorporado de outros sites</b><br />
                        Os artigos neste site podem incluir conteúdo incorporado (por exemplo:
                         vídeos, imagens, artigos, etc.). O conteúdo incorporado de outros sites
                          comporta-se tal como se o utilizador visitasse esses sites.

                        Esses sites podem recolher dados sobre si, usar cookies, incorporar rastreio
                         feito por terceiros, monitorizar as suas interações com o mesmo, incluindo
                          registar as interações com conteúdo incorporado se tiver uma conta e estiver
                           com sessão iniciada nesse site.<br /><br />

                        <b>Links para outros sites</b> <br />
                        No nosso website, em www.zitacletomakeup.com, poderão estar incluídos links de acesso a
                        outros websites que são alheios à  ZitaCletoMakeup & Beauty. A disponibilização de tais links é
                         efetuada de boa-fé, não podendo a  ZitaCletoMakeup & Beauty ser responsabilizada pela recolha e
                         tratamento de dados pessoais efetuados através desses websites, nem assumir
                         qualquer responsabilidade relativamente a tais websites, nomeadamente quanto
                          à exatidão, credibilidade e funcionalidades disponibilizadas nos mesmos.<br /><br />
                    </p>
                    <h3>
                        Como são tratados os seus dados
                    </h3>
                    <p>
                        O tratamento de dados pessoais consiste numa operação ou conjunto de operações
                        efetuadas sobre dados pessoais ou conjuntos de dados pessoais, através de meios
                        automatizados, ou não, nomeadamente a recolha, o registo, a organização, a
                        estruturação, a conservação, a adaptação, a recuperação, a consulta, a utilização,
                        a divulgação, difusão, comparação, interconexão, a limitação, o apagamento ou a
                        destruição.<br />

                        A  ZitaCletoMakeup & Beauty obriga-se a garantir em todos os casos o estrito cumprimento da legislação
                        de proteção de dados pessoais, que são armazenados em bases de dados específicas,
                        criadas para o efeito e, em situação alguma, os dados recolhidos serão utilizados
                        para outra finalidade que não seja aquela para a qual foram recolhidos ou dado o
                        consentimento por parte do titular dos dados.<br /><br />
                    </p>
                    <h3>
                        Com quem são partilhados os seus dados
                        </h3>
                    <p>
                        Os dados recolhidos neste site são para utilização exclusiva da  ZitaCletoMakeup & Beauty.
                        </p><br />

                    <h3>
                        Por quanto tempo são retidos os seus dados
                        </h3>
                    <p>
                        Guardamos os seus dados durante o tempo necessário à boa prestação de
                        informações ou serviços.
                    </p><br />

                    <h3>
                        Segurança
                        </h3>
                    <p>
                        A ZitaCletoMakeup & Beauty assume o compromisso de garantir a proteção da segurança
                        dos dados pessoais que lhe são disponibilizados, tendo aprovado e implementado rigorosas
                        regras nesta matéria. O cumprimento destas regras constitui uma obrigação de todos
                        aqueles que legalmente aos mesmos acedem.<br />

                        Tendo presente a preocupação e empenho que a  ZitaCletoMakeup & Beauty revela na defesa dos dados pessoais,
                        foram adotadas medidas de segurança, de carácter técnico e organizativo, de forma a
                        proteger os dados pessoais que lhe são disponibilizados contra a sua difusão, perda,
                        uso indevido, alteração, tratamento ou acesso não autorizado, bem como contra qualquer
                        outra forma de tratamento ilícito.<br />

                        O acesso às informações pessoais dos Clientes está limitado aos colaboradores que têm uma
                        necessidade legítima de vir a ter contacto com essas informações para prestarem serviços
                        ao Cliente, no cumprimento das suas funções.<br />

                        Adicionalmente, as entidades terceiras que, no âmbito de prestações de serviços, procedam
                        ao tratamento de dados pessoais dos Clientes em nome e por conta da  ZitaCletoMakeup & Beauty, estão obrigadas,
                        por escrito, a executar medidas técnicas e de segurança adequadas que, em cada momento,
                        satisfaçam os requisitos previstos na legislação em vigor e assegurem a defesa dos direitos
                        do titular dos dados (nomeadamente, a proteção da privacidade e dos dados pessoais dos Clientes).<br />

                        Assim, qualquer entidade subcontratada pela  ZitaCletoMakeup & Beauty tratará os dados pessoais dos nossos Clientes,
                        em nome e por conta da  ZitaCletoMakeup & Beauty e adotando medidas técnicas e organizacionais necessárias de forma
                        a proteger os dados pessoais contra a destruição, acidental ou ilícita, a perda acidental, a
                        alteração, a difusão ou o acesso não autorizado e contra qualquer outra forma de tratamento
                        ilícito. Em qualquer dos casos, a  ZitaCletoMakeup & Beauty permanece responsável pelos dados pessoais que lhe
                        sejam disponibilizados.<br />

                        Não obstante as medidas de segurança adotadas pela  ZitaCletoMakeup & Beauty, alertamos a todos os que navegam na
                        Internet que devem adotar medidas adicionais de segurança, designadamente, assegurar que utilizam
                        um PC e um Browser atualizados em termos de “patches” de segurança adequadamente configurados
                        , com firewall ativa, antivírus e anti-spyware e que se certifiquem da autenticidade dos sites
                        que visitam na internet, devendo evitar websites em cuja reputação não confiem.<br /><br />
                    </p>



                </div>
                <Footer />
            </div>
        )
    }
}
