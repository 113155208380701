import React, { Component } from "react";
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import logoCasamentos from "../img/logo-casamentos.png";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <a href="https://www.facebook.com/zitacletomakeup">
          <FaFacebook />
        </a>

        <a href="https://www.instagram.com/zitacleto.makeup/">
          <IoLogoInstagram />
        </a>

        <a href="https://www.casamentos.pt/beleza-noivas/zita-cleto-make-up--e115322">
          <img src={logoCasamentos} alt="casamentos.pt"></img>
        </a>

        <div className="insidefooter">
          <p className="ignited">
            <Link to="/politicaprivacidade">Politica de Privacidade</Link>- ©
            2023 IgnitedBytes
          </p>
        </div>
      </div>
    );
  }
}
