import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaAlignRight } from 'react-icons/fa'
import logo from "../img/Logo_1.png"


export default class NavbarSecundaria extends Component {
    state = {
        isOpen1: false
    };
    handleToggle = () => {
        this.setState({ isOpen1: !this.state.isOpen1 })
    }


    render() {
        return (
            <div className="navbar-secundaria" id="navbar-secundaria">
                <div className="nav-center-secundaria">
                    <div className="nav-header-secundaria">
                        <Link to="/">
                            <img src={logo} alt="ZitaCletoLogo"></img>
                        </Link>
                    </div>
                    <button type="button" className="nav-btn-secundaria">
                        <FaAlignRight className="nav-icon-secundaria" onClick={this.handleToggle} />
                    </button>

                    <ul className={this.state.isOpen1 ? "nav-links-secundaria show-nav-secundaria" : "nav-links-secundaria"}>

                        <li>
                            <Link to="/" onClick={this.handleToggle}> Home</Link>
                        </li>
                        <li>
                            <Link to="/portefolio" onClick={this.handleToggle}> Portefólio</Link>
                        </li>



                        <img src={logo} className="logoNavbarSecundaria" alt="ZitaCleto" ></img>
                    </ul>
                </div>

            </div>
        )
    }
}

