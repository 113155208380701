import React, { Suspense, lazy } from "react";
import NavbarSecundaria from "../components/NavbarSecundaria";

const BackToTop = lazy(() => import("../components/BackToTop"));
const InstaPortefolio = lazy(() => import("../components/InstaPortefolio"));

const PortefolioHidrataçaoProfundaLabios = () => {
  return (
    <div className="portefoliomaquilhagem">
      <NavbarSecundaria />
      <h1>Hidratação Profunda de Lábios </h1>

      <Suspense fallback={<p>A carregar...</p>}>
        {" "}
        <InstaPortefolio filtro={"HIDRATAÇÃO PROFUNDA DE LÁBIOS"} after={""} />
      </Suspense>

      <Suspense fallback={<p></p>}>
        {" "}
        <BackToTop ancora="#navbar-secundaria" />
      </Suspense>
    </div>
  );
};

export default PortefolioHidrataçaoProfundaLabios;
