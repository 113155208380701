import React from "react";
import "./App.css";
import Home from "./pages/Home";
import PortefolioLifting from "./pages/PortefolioLifting";
import PortefolioMicroblading from "./pages/PortefolioMicroblading";
import PortefolioMaquilhagem from "./pages/PortefolioMaquilhagem";
import PortefolioNoiva from "./pages/PortefolioNoiva";
import Portefolio from "./pages/Portefolio";
import { Route, Switch } from "react-router-dom";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import Erro404 from "./pages/Erro404";
import Workshops from "./pages/Workshops";
import PortefolioRemoTatuagens from "./pages/PortefolioRemoTatuagens";
import PortefolioPeelingHollywood from "./pages/PortefolioPeelingHollywood";
import PortefolioRemoçaoLaserMicro from "./pages/PortefolioRemoçaoLaserMicro";
import PortefolioMicropigmentaçaoLabios from "./pages/PortefolioMicropigmentaçaoLabios";
import PortefolioHidrataçaoProfundaLabios from "./pages/PortefolioHidrataçaoProfundaLabios";
import ServiçosPage from "./pages/ServiçosPage";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/serviços" component={ServiçosPage} />
        <Route exact path="/portefolio" component={Portefolio} />
        <Route exact path="/portefoliolifting" component={PortefolioLifting} />
        <Route
          exact
          path="/portefoliomicroblading"
          component={PortefolioMicroblading}
        />
        <Route
          exact
          path="/portefoliomaquilhagem"
          component={PortefolioMaquilhagem}
        />
        <Route
          exact
          path="/portefoliomaquilhagemnoiva"
          component={PortefolioNoiva}
        />
        <Route
          exact
          path="/portefolioremoçaotatuagens"
          component={PortefolioRemoTatuagens}
        />
        <Route
          exact
          path="/portefoliopeelinghollywood"
          component={PortefolioPeelingHollywood}
        />
        <Route
          exact
          path="/portefolioremoçaolasermicro"
          component={PortefolioRemoçaoLaserMicro}
        />
        <Route
          exact
          path="/portefoliomicropilabios"
          component={PortefolioMicropigmentaçaoLabios}
        />
        <Route
          exact
          path="/portefoliohidrataçaolabios"
          component={PortefolioHidrataçaoProfundaLabios}
        />

        <Route
          exact
          path="/politicaprivacidade"
          component={PoliticaPrivacidade}
        />

        <Route
          exact
          path="/portefolioremotatuagens"
          component={PortefolioRemoTatuagens}
        />
        <Route exact path="/workshops" component={Workshops} />
        <Route component={Erro404} />
      </Switch>
      {/**/}
    </div>
  );
}

export default App;
