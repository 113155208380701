import React, { Component } from 'react'
import img_slide1 from '../img/img_slide1.png'
import img_slide2 from '../img/img_slide2.png'
import img_slide3 from '../img/img_slide3.png'
import img_slide5 from '../img/img_slide5.png'
import img_slide6 from '../img/img_slide6.png'
import img_slide7 from '../img/img_slide7.png'

export default class SlideShow extends Component {

    render() {

        return (<div className="slideShow" >
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt"><img alt="ZitaCleto Make Up" src={img_slide1}></img></a>
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt"> <img alt="ZitaCleto Make Up" src={img_slide2}></img></a>
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt"> <img alt="ZitaCleto Make Up" src={img_slide3}></img></a>
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt">  <img alt="ZitaCleto Make Up" src={img_slide5}></img></a>
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt">   <img alt="ZitaCleto Make Up" src={img_slide6}></img></a>
            <a href="https://www.instagram.com/zitacleto.makeup/?hl=pt">  <img alt="ZitaCleto Make Up" src={img_slide7}></img></a>
        </div >

        )
    }
}
